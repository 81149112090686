import React from "react";

import Footer from "../components/footer";
import PageLayout from "../components/page-layout";

import "../styles/global.css";
import * as style from "./submission-success.module.css";

import ukraineImage from "../images/ukraine.png";

const SubmissionSuccessPage = () => {
  return (
    <PageLayout
      title="Call A Colleague - Success"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section className={style.section}>
        <h1>Success!</h1>
        <img src={ukraineImage} width="50" />
        <p>Please check your mail in about 5 mins.</p>

        <p>
          <b>
            You learned about us through social media advertisement. We invest
            ~2500 EURO in ads monthly. It costs more than 1€ to inspire a person
            to book a call on average.
          </b>
        </p>
        <p className={style.special}>
          Donate 15€ to empower 15 other colleagues abroad!
        </p>

        <p>
          <a
            href="https://www.paypal.com/donate/?hosted_button_id=V8CPDUQJQL42L"
            className={style.donateButton}
          >
            Donate
          </a>
        </p>
      </section>

      <Footer />
    </PageLayout>
  );
};

export default SubmissionSuccessPage;
